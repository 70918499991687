// ./utils/ansiToHtml.ts
export function ansiToHtml(text: string): string {
  const ansiRegex = /\x1b\[(\d+(?:;\d+)*)m/g;
  const ansiToHtmlMap: { [key: string]: string } = {
    "0": "</span>", // Reset
    "31": '<span style="color:red">', // Rot
    "32": '<span style="color:green">', // Grün
    "33": '<span style="color:yellow">', // Gelb
    "34": '<span style="color:blue">', // Blau
    "35": '<span style="color:magenta">', // Magenta
    "36": '<span style="color:cyan">', // Cyan
    "37": '<span style="color:white">', // Weiß
    "38;5;245": '<span style="color:grey">', // Grau (ANSI 256 Farben)
  };

  return text.replace(ansiRegex, (_, code) => ansiToHtmlMap[code] || "");
}
